export enum enuKunde {
  AVS = 1,
  KFS = 2,
  Standard = 3,
  Template = 4
}

export enum enuEingabeMitgliedsnummer {
  MitSektion = 1,
  OhneSektion = 2,
  Kontakt = 3
}

export interface IAppConfig {
  apiUrl: string;
  restService: string;
  fotoService: string;
  myBase: string;
  debug: boolean;
  testversion: boolean;
  showinfo: boolean;
  cookiedomain: string;
  kunde: enuKunde;
  kundenname: string;
  updateLiveReload: boolean,
  secondsBeforeReload: number,
  sperrung:boolean;
  originUrl:boolean;
}

export class IKundeConfig {
  kursbuchen: boolean;
  taetigkeitenbuchen: boolean;

  neuMenu: boolean = false;
  persdaten: boolean = true;
  mitgliedsdaten: boolean = true;
  kletterprofil: boolean = true;
  familie: boolean = true;
  zeitung: boolean = true;
  zahlungsart: boolean = true;
  mitgliedsbeitrag: boolean = true;
  funktionen: boolean = true;
  qualifikationen: boolean = true;
  weiterbildung: boolean = true;
  kurse: boolean = true;
  taetigkeiten: boolean = true;
  ehrungen: boolean = true;
  dokumente: boolean = true;
  newsletter: boolean = true;
  mitgliedskarte: boolean = true;
  passwordaendern: boolean = true;

  kontakte: boolean;
  kontaktnummer: boolean;
  mitgliedsnummer: boolean = true;
  zahlschein: boolean;
  kreditkarte: boolean;
  lastschrift: boolean;
  sprachauswahl: boolean;
  stichtag: boolean;
  erweitertesLogin: boolean;

  setData(data: IKundeConfig) {
    this.kursbuchen = data.kursbuchen;
    this.taetigkeitenbuchen = data.taetigkeitenbuchen;

    if(typeof data.neuMenu == "boolean") this.neuMenu = data.neuMenu;
    if(typeof data.persdaten == "boolean") this.persdaten = data.persdaten;
    if(typeof data.mitgliedsdaten == "boolean") this.mitgliedsdaten = data.mitgliedsdaten;
    if(typeof data.kletterprofil == "boolean") this.kletterprofil = data.kletterprofil;
    if(typeof data.familie == "boolean") this.familie = data.familie;
    if(typeof data.zeitung == "boolean") this.zeitung = data.zeitung;
    if(typeof data.zahlungsart == "boolean") this.zahlungsart = data.zahlungsart;
    if(typeof data.mitgliedsbeitrag == "boolean") this.mitgliedsbeitrag = data.mitgliedsbeitrag;
    if(typeof data.funktionen == "boolean") this.funktionen = data.funktionen;
    if(typeof data.qualifikationen == "boolean") this.qualifikationen = data.qualifikationen;
    if(typeof data.weiterbildung == "boolean") this.weiterbildung = data.weiterbildung;
    if(typeof data.kurse == "boolean") this.kurse = data.kurse;
    if(typeof data.taetigkeiten == "boolean") this.taetigkeiten = data.taetigkeiten;
    if(typeof data.ehrungen == "boolean") this.ehrungen = data.ehrungen;
    if(typeof data.dokumente == "boolean") this.dokumente = data.dokumente;
    if(typeof data.newsletter == "boolean") this.newsletter = data.newsletter;
    if(typeof data.mitgliedskarte == "boolean") this.mitgliedskarte = data.mitgliedskarte;
    if(typeof data.passwordaendern == "boolean") this.passwordaendern = data.passwordaendern;

    this.kontakte = data.kontakte;
    this.kontaktnummer = data.kontaktnummer;
    if(typeof data.mitgliedsnummer == "boolean") this.mitgliedsnummer = data.mitgliedsnummer;
    this.zahlschein = data.zahlschein;
    this.kreditkarte = data.kreditkarte;
    this.lastschrift = data.lastschrift;
    this.sprachauswahl = data.sprachauswahl;
    this.stichtag = data.stichtag;
    this.erweitertesLogin = data.erweitertesLogin;
  }

}
