/**
 * Creates an array of elements split into groups the length of `size`. If `array` can't be split evenly, the final chunk will be the remaining elements.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 2,206 bytes
 * - Micro-dash: 129 bytes
 */
function chunk(array, size = 1) {
  size = Math.max(Math.trunc(size), 0);
  const chunks = [];
  for (let i = 0; i < array.length; i += Math.max(1, size)) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

/**
 * Creates a function that returns `value`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 48 bytes
 * - Micro-dash: 4 bytes
 */
function constant(value) {
  return () => value;
}

/**
 * This method returns the first argument it receives.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 41 bytes
 * - Micro-dash: 23 bytes
 */
function identity(value) {
  return value;
}
function flow(...funcs) {
  if (funcs.length) {
    return funcs.reduce((result, func) => input => func(result(input)));
  } else {
    return identity;
  }
}
function flowRight(...funcs) {
  if (funcs.length) {
    return funcs.reduce((result, func) => input => result(func(input)));
  } else {
    return identity;
  }
}

/**
 * Casts `value` as an array if it's not one.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 128 bytes
 * - Micro-dash: 44 bytes
 */
function castArray(value) {
  return Array.isArray(value) ? value : [value];
}

/**
 * Creates a shallow clone of `value`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 12,289 bytes
 * - Micro-dash: 90 bytes
 */
function clone(value) {
  if (Array.isArray(value)) {
    return value.slice();
  } else if (value instanceof Object) {
    return Object.assign({}, value);
  } else {
    return value;
  }
}
function forEach(collection, iteratee) {
  if (Array.isArray(collection)) {
    forEachOfArray(collection, iteratee);
  } else {
    forOwnOfNonArray(collection, iteratee);
  }
  return collection;
}
/** @hidden */
function forEachOfArray(array, iteratee) {
  for (let i = 0, len = array.length; i < len; ++i) {
    if (iteratee(array[i], i) === false) {
      break;
    }
  }
}

/**
 * Creates an array of the own enumerable property names of object.
 *
 * Differences from lodash:
 * - does not give any special consideration for arguments objects, strings, or prototype objects (e.g. many will have `'length'` in the returned array)
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,326 bytes
 * - Micro-dash: 148 bytes
 */
function keys(object) {
  let val = keysOfNonArray(object);
  if (Array.isArray(object)) {
    val = val.filter(item => item !== 'length');
  }
  return val;
}
/** @hidden */
function keysOfNonArray(object) {
  let val = [];
  if (object) {
    val = Object.getOwnPropertyNames(object);
  }
  return val;
}

/**
 * Iterates over own enumerable string keyed properties of an object and invokes `iteratee` for each property. Iteratee functions may exit iteration early by explicitly returning `false`.
 *
 * Differences from lodash:
 * - does not treat sparse arrays as dense
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,514 bytes
 * - Micro-dash: 213 bytes
 */
function forOwn(object, iteratee) {
  forEachOfArray(keys(object), key => iteratee(object[key], key));
  return object;
}
/** @hidden */
function forOwnOfNonArray(object, iteratee) {
  forEachOfArray(keysOfNonArray(object), key => iteratee(object[key], key));
  return object;
}
function findKey(object, predicate) {
  let found;
  forOwn(object, (value, key) => {
    if (predicate(value, key)) {
      found = key;
      return false;
    }
  });
  return found;
}
function forEachRight(collection, iteratee) {
  if (Array.isArray(collection)) {
    forEachRightOfArray(collection, iteratee);
  } else {
    forOwnRightOfNonArray(collection, iteratee);
  }
  return collection;
}
/** @hidden */
function forEachRightOfArray(array, iteratee) {
  for (let i = array.length; --i >= 0;) {
    if (iteratee(array[i], i) === false) {
      break;
    }
  }
}

/**
 * This method is like `forOwn` except that it iterates over properties of `object` in the opposite order.
 *
 * Differences from lodash:
 * - does not treat sparse arrays as dense
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,507 bytes
 * - Micro-dash: 191 bytes
 */
function forOwnRight(object, iteratee) {
  forEachRightOfArray(keys(object), key => iteratee(object[key], key));
  return object;
}
/** @hidden */
function forOwnRightOfNonArray(object, iteratee) {
  forEachRightOfArray(keysOfNonArray(object), key => iteratee(object[key], key));
  return object;
}

/**
 * Creates an array of function property names from own enumerable properties of `object`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,504 bytes
 * - Micro-dash: 225 bytes
 */
function functions(obj) {
  return keys(obj).filter(key => key !== 'constructor' && isFunction(obj[key]));
}
function get(object, path, defaultValue) {
  // const val = property(path)(object);
  // return isUndefined(val) ? defaultValue : val;
  path = castArray(path);
  const length = path.length;
  let index = 0;
  while (object != null && index < length) {
    object = object[path[index++]];
  }
  return !index || index < length || object === undefined ? defaultValue : object;
}
function invoke(object, path, ...args) {
  const fn = get(object, path);
  if (isFunction(fn)) {
    return fn.apply(path.length === 1 ? object : get(object, path.slice(0, -1)), args);
  }
}
function mapValues(object, iteratee) {
  const obj = {};
  forOwn(object, (value, key) => {
    obj[key] = iteratee(value, key);
  });
  return obj;
}
function merge(object, ...sources) {
  for (const source of sources) {
    forEach(source, (value, key) => {
      const myValue = object[key];
      if (myValue instanceof Object) {
        value = merge(clone(myValue), value);
      }
      object[key] = value;
    });
  }
  return object;
}

/**
 * The opposite of `pick`; this method creates an object composed of the own enumerable string properties of object that are not omitted.
 *
 * Differences from lodash:
 * - `paths` must be direct keys of `object` (they cannot refer to deeper properties)
 * - does not work with arrays
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 15,603 bytes
 * - Micro-dash: 138 bytes
 */
function omit(object, ...paths) {
  const obj = clone(object) || {};
  for (const path of paths) {
    delete obj[path];
  }
  return obj;
}
function pickBy(object, predicate) {
  const obj = {};
  forOwn(object, (item, key) => {
    if (predicate(item, key)) {
      obj[key] = item;
    }
  });
  return obj;
}
function omitBy(object, predicate) {
  return pickBy(object, (item, key) => !predicate(item, key));
}

/**
 * Creates an object composed of the picked `object` properties.
 *
 * Differences from lodash:
 * - `paths` must be direct properties of `object` (they cannot references deep properties)
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 7,691 bytes
 * - Micro-dash: 77 bytes
 */
function pick(object, ...paths) {
  const result = {};
  if (object != null) {
    for (const path of paths) {
      result[path] = object[path];
    }
  }
  return result;
}

/**
 * This method is like `set()` except that it accepts `updater` to produce the value to set.
 *
 * **Note:** This method mutates `object`.
 *
 * Differences from lodash:
 * - only accepts an array for `path`, not a dot-separated string
 * - does not handle `customizer` returning `undefined`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 5,842 bytes
 * - Micro-dash: 177 bytes
 */
function update(object, path, updater) {
  if (object && path.length) {
    let current = object;
    const length = path.length;
    for (let i = 0; i < length; ++i) {
      const key = path[i];
      let value = current[key];
      if (i < length - 1) {
        if (!(value instanceof Object)) {
          value = Number.isInteger(path[i + 1]) ? [] : {};
        }
      } else {
        value = updater(value);
      }
      current = current[key] = value;
    }
  }
  return object;
}

/**
 * Sets the value at `path` of `object`. If a portion of `path` doesn't exist, it's created. Arrays are created for missing index properties while objects are created for all other missing properties.
 *
 * **Note:** This method mutates `object`.
 *
 * Differences from lodash:
 * - only accepts an array for `path`, not a dot-separated string
 * - does not handle `customizer` returning `undefined`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 5,639 bytes
 * - Micro-dash: 178 bytes
 */
function set(object, path, value) {
  return update(object, path, constant(value));
}

/**
 * Creates an array of own enumerable string keyed-value pairs for `object` which can be consumed by `fromPairs`.
 *
 * Differences from lodash:
 * - does not give any special consideration for arguments objects, strings, or prototype objects (e.g. many will have `'length'` in the returned array)
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 4,911 bytes
 * - Micro-dash: 175 bytes
 */
function toPairs(object) {
  return keys(object).map(key => [key, object[key]]);
}
function every(collection, predicate) {
  let result = true;
  forEach(collection, (value, keyOrIndex) => result = !!predicate(value, keyOrIndex));
  return result;
}
function filter(collection, predicate) {
  const result = [];
  forEach(collection, (item, indexOrKey) => {
    if (predicate(item, indexOrKey)) {
      result.push(item);
    }
  });
  return result;
}
function find(collection, predicate, fromIndex = 0) {
  if (Array.isArray(collection)) {
    return collection.slice(fromIndex).find((item, index) => predicate(item, index));
  } else {
    for (const key of keysOfNonArray(collection).slice(fromIndex)) {
      const item = collection[key];
      if (predicate(item, key)) {
        return item;
      }
    }
  }
}
function map(collection, iteratee) {
  const mapped = [];
  forEach(collection, (value, keyOrIndex) => {
    mapped.push(iteratee(value, keyOrIndex));
  });
  return mapped;
}
function flatMap(collection, iteratee) {
  return flatten(map(collection, iteratee));
}

/**
 * Creates an object composed of keys generated from the results of running each element of `collection` thru `iteratee`. The order of grouped values is determined by the order they occur in `collection`. The corresponding value of each key is an array of elements responsible for generating the key.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 14,388 bytes
 * - Micro-dash: 322 bytes
 */
function groupBy(collection, iteratee) {
  return transform(collection, (accumulator, value) => {
    const key = iteratee(value);
    let group = accumulator[key];
    if (!Array.isArray(group)) {
      accumulator[key] = group = [];
    }
    group.push(value);
  }, {});
}

/**
 * Checks if `value` is in `collection`. If `collection` is a string, it's checked for a substring of `value`. If `fromIndex` is negative, it's used as the offset from the end of `collection`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 4,547 bytes
 * - Micro-dash: 269 bytes
 */
function includes(collection, value, fromIndex = 0) {
  if (isString(collection)) {
    if (fromIndex > collection.length) {
      return false;
    }
  } else {
    collection = toArray(collection);
  }
  return collection.includes(value, fromIndex);
}
function keyBy(collection, iteratee) {
  const obj = {};
  forEach(collection, value => {
    obj[iteratee(value)] = value;
  });
  return obj;
}

/** @hidden */
function doReduce(iterationFn, collection, iteratee, accumulator, initAccum) {
  iterationFn(collection, (value, indexOrKey) => {
    if (initAccum) {
      accumulator = value;
      initAccum = false;
    } else {
      accumulator = iteratee(accumulator, value, indexOrKey);
    }
  });
  return accumulator;
}
function reduce(collection, iteratee, accumulator) {
  return doReduce(forEach, collection, iteratee, accumulator, arguments.length < 3);
}
function reduceRight(collection, iteratee, accumulator) {
  return doReduce(forEachRight, collection, iteratee, accumulator, arguments.length < 3);
}

/** @hidden */
function findExtreme(array, iteratee, shouldReplace) {
  let current;
  let currentCriterion;
  forEachOfArray(array, candidate => {
    const candidateCriterion = iteratee(candidate);
    if (current === undefined || shouldReplace(candidateCriterion, currentCriterion)) {
      current = candidate;
      currentCriterion = candidateCriterion;
    }
  });
  // tslint:disable-next-line:no-non-null-assertion
  return current;
}

/**
 * This method is like `max` except that it accepts `iteratee` which is invoked for each element in `array` to generate the criterion by which the value is ranked.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 8,343 bytes
 * - Micro-dash: 143 bytes
 */
function maxBy(array, iteratee) {
  return findExtreme(array, iteratee, (candidate, current) => candidate > current);
}

/**
 * This method is like `min` except that it accepts `iteratee` which is invoked for each element in `array` to generate the criterion by which the value is ranked.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 8,343 bytes
 * - Micro-dash: 143 bytes
 */
function minBy(array, iteratee) {
  return findExtreme(array, iteratee, (candidate, current) => candidate < current);
}
function random(...args) {
  let lower = 0;
  let upper = 1;
  if (isFinite(args[1])) {
    [lower, upper] = args;
  } else if (isFinite(args[0])) {
    [upper] = args;
  }
  [lower, upper] = [lower, upper].sort();
  let floating = last(args);
  if (!isBoolean(floating)) {
    floating = !Number.isInteger(lower) && !Number.isInteger(upper);
  }
  let range = upper - lower;
  if (!floating) {
    ++range;
  }
  let result = Math.random() * range + lower;
  if (!floating) {
    result = Math.floor(result);
  }
  return result;
}
// /**
//  * Produces a random number between the inclusive `lower` and `upper` bounds. If only one argument is provided a number between `0` and the given number is returned. If `floating` is true, or either `lower` or `upper` are floats, a floating-point number is returned instead of an integer.
//  *
//  * **Note:** JavaScript follows the IEEE-754 standard for resolving floating-point values which can produce unexpected results.
//  *
//  * Differences from lodash:
//  * - requires each argument to be provided in its documented spot. E.g. `floating` must always be the third argument, and `lower` should not be greater than `upper`.
//  * - does not coerce arguments into finite numbers. E.g. if you supply `NaN` or `Infinity`, results are not guaranteed.
//  *
//  * Contribution to minified bundle size, when it is the only function imported:
//  */
// export function random(
//   lower = 0,
//   upper = 1,
//   floating = !(Number.isInteger(lower) && Number.isInteger(upper)),
// ) {
//   let range = upper - lower;
//   if (!floating) {
//     ++range;
//   }
//   let result = Math.random() * range + lower;
//   if (!floating) {
//     result = Math.floor(result);
//   }
//   return result;
// }

/**
 * Computes `number` rounded to `precision`.
 *
 * ```ts
 * round(4.006); // 4
 * round(4.006, 2); // 4.01
 * round(4060, -2); // 4100
 * ```
 *
 * Differences from lodash:
 * - may return `NaN` with large `precision` values
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,822 bytes
 * - Micro-dash: 78 bytes
 */
// tslint:disable-next-line:variable-name
function round(number, precision = 0) {
  const factor = Math.pow(10, Math.trunc(precision));
  return Math.round(number * factor) / factor;
}

/**
 * This method is like `sum` except that it accepts `iteratee` which is invoked for each element in `array` to generate the value to be summed. The iteratee is invoked with one argument: (value).
 *
 * Differences from lodash:
 * - does not skip `undefined` values
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 8,292 bytes
 * - Micro-dash: 22 bytes
 */
function sumBy(array, iteratee) {
  return array.reduce((sum, element) => sum + iteratee(element), 0);
}

/**
 * Gets a random element from `collection`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,655 bytes
 * - Micro-dash: 435 bytes
 */
function sample(collection) {
  collection = toArray(collection);
  return collection[random(collection.length - 1)];
}
function size(collection) {
  return keys(collection).length;
}
function some(collection, predicate) {
  let none = true;
  forEach(collection, (item, keyOrIndex) => none = !predicate(item, keyOrIndex));
  return !none;
}

/**
 * Creates an array of elements, sorted in ascending order by the results of running each element in a collection thru each iteratee. This method performs a stable sort, that is, it preserves the original sort order of equal elements.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 16,054 bytes
 * - Micro-dash: 612 bytes
 */
function sortBy(collection, iteratees) {
  const fns = castArray(iteratees);
  const metas = map(collection, value => Object.assign(fns.map(fn => fn(value)), {
    value
  }));
  metas.sort((m1, m2) => {
    for (let i = 0; i < m1.length; ++i) {
      const comp = compareValues(m1[i], m2[i]);
      if (comp) {
        return comp;
      }
    }
  });
  return metas.map(e => e.value);
}
/** @hidden */
const compareValues = (x, y) => {
  let v1 = getSortOrdinal(x);
  let v2 = getSortOrdinal(y);
  if (v1 === v2) {
    v1 = x;
    v2 = y;
  }
  if (v1 < v2) {
    return -1;
  } else if (v1 > v2) {
    return 1;
  }
};
/** @hidden */
const getSortOrdinal = value => {
  if (Number.isNaN(value)) {
    return 3;
  }
  if (value === undefined) {
    return 2;
  }
  if (value === null) {
    return 1;
  }
  return 0;
};
const ɵ0 = getSortOrdinal;
function transform(object, iteratee, accumulator = {}) {
  forEach(object, (value, key) => iteratee(accumulator, value, key));
  return accumulator;
}

/**
 * Creates an array of the own enumerable string keyed property values of `object`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,422 bytes
 * - Micro-dash: 170 bytes
 */
function values(object) {
  return keys(object).map(key => object[key]);
}
/** @hidden */
function valuesOfNonArray(object) {
  return keysOfNonArray(object).map(key => object[key]);
}

/**
 * This method is like `clone` except that it recursively clones `value`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 12,289 bytes
 * - Micro-dash: 397 bytes
 */
function cloneDeep(value) {
  if (value instanceof Object) {
    value = clone(value);
    forOwn(value, (item, key) => {
      value[key] = cloneDeep(item);
    });
  }
  return value;
}

/**
 * Checks if `value` is classified as a boolean primitive.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 693 bytes
 * - Micro-dash: 35 bytes
 */
function isBoolean(value) {
  return value === true || value === false;
}

/**
 * Checks if `value` is an empty object or collection.
 *
 * Objects are considered empty if they have no own enumerable string keyed properties.
 *
 * Arrays are considered empty if they have a `length` of `0`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 4,321 bytes
 * - Micro-dash: 127 bytes
 */
function isEmpty(value) {
  if (!Array.isArray(value)) {
    value = keysOfNonArray(value);
  }
  return value.length === 0;
}

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 *
 * Differences from lodash:
 * - cannot handle circular references
 * - does not give special treatment to arrays; their own properties are compared just like other objects. Note this means sparse arrays are not equal to their dense "equivalents".
 * - `isEqual(0, -0) === false`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 10,811 bytes
 * - Micro-dash: 276 bytes
 */
function isEqual(value, other) {
  if (Object.is(value, other)) {
    // covers e.g. NaN === NaN
    return true;
  }
  if (!(value instanceof Object && other instanceof Object)) {
    return false;
  }
  for (const key of keysOfNonArray(value)) {
    if (!other.hasOwnProperty(key)) {
      return false;
    }
  }
  for (const key of keysOfNonArray(other)) {
    if (!isEqual(value[key], other[key])) {
      return false;
    }
  }
  return true;
}

/**
 * Checks if `value` is classified as a `Function` object.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 824 bytes
 * - Micro-dash: 41 bytes
 */
function isFunction(value) {
  return typeof value === 'function';
}

/**
 * Performs a partial deep comparison between `object` and `source` to determine if `object` contains equivalent property values.
 *
 * *Note:* This method is equivalent to `matches` when source is partially applied.
 *
 * Partial comparisons will match empty array and empty object source values against any array or object value, respectively.
 *
 * Differences from lodash:
 * - does not match `0` to `-0`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 11,222 bytes
 * - Micro-dash: 650 bytes
 */
function isMatch(object, source) {
  return isEmpty(source) || isMatch0(object, source);
}
/** @hidden */
function isMatch0(object, source) {
  if (Object.is(object, source)) {
    return true;
  }
  if (!(object instanceof Object && source instanceof Object)) {
    return false;
  }
  const isArray = Array.isArray(object);
  if (isArray !== Array.isArray(source)) {
    return false;
  }
  if (isArray) {
    const unmatched = object.slice();
    for (const innerSource of source) {
      const i = unmatched.findIndex(item => isMatch0(item, innerSource));
      if (i < 0) {
        return false;
      }
      unmatched.splice(i, 1);
    }
    return true;
  } else {
    return every(source, (value, key) => object.hasOwnProperty(key) && isMatch0(object[key], value));
  }
}

/**
 * Checks if value is `null` or `undefined`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 78 bytes
 * - Micro-dash: 29 bytes
 */
function isNil(value) {
  return value == null;
}

/**
 * Checks if `value` is classified as a `Number` primitive.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 695 bytes
 * - Micro-dash: 39 bytes
 */
function isNumber(value) {
  return typeof value === 'number';
}

/**
 * Checks if `value` is classified as a `RegExp` object.
 *
 * Differences from lodash:
 * - returns `true` for subclasses of `RegExp`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,040 bytes
 * - Micro-dash: 41 bytes
 */
function isRegExp(value) {
  return value instanceof RegExp;
}

/**
 * Checks if `value` is classified as a String primitive.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 752 bytes
 * - Micro-dash: 39 bytes
 */
function isString(value) {
  return typeof value === 'string';
}

/**
 * Checks if `value` is `undefined`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 81 bytes
 * - Micro-dash: 32 bytes
 */
function isUndefined(value) {
  return value === undefined;
}
function toArray(value) {
  if (value && value[Symbol.iterator]) {
    return Array.from(value);
  } else {
    return valuesOfNonArray(value);
  }
}

/**
 * Converts `value` to a string. An empty string is returned for `null` and `undefined` values.
 *
 * Differences:
 * - `null` and `undefined` array references are blank
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,049 bytes
 * - Micro-dash: 35 bytes
 */
function toString(value) {
  return [value].toString();
}

/**
 * Creates a function that performs a partial deep comparison between a given `object` and `source`, returning `true` if the given object has equivalent property values, else `false`.
 *
 * Note: The created function is equivalent to `isMatch` with source partially applied.
 *
 * Partial comparisons will match empty array and empty object source values against any array or object value, respectively.
 *
 * Differences from lodash:
 * - does not match `0` to `-0`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 15,939 bytes
 * - Micro-dash: 955 bytes
 */
function matches(source) {
  source = cloneDeep(source);
  return value => isMatch(value, source);
}

/**
 * This method returns undefined.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 47 bytes
 * - Micro-dash: 14 bytes
 */
function noop() {}

/**
 * Invokes the iteratee `n` times, returning an array of the results of each invocation.
 *
 * Differences from lodash:
 * - has undefined behavior when given a non natural number for `n`
 * - does not provide a default for `iteratee`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,497 bytes
 * - Micro-dash: 51 bytes
 */
function times(n, iteratee) {
  const result = [];
  for (let i = 0; i < n; ++i) {
    result[i] = iteratee(i);
  }
  return result;
}
function range(start, end, step) {
  if (isUndefined(end)) {
    end = start;
    start = 0;
  }
  if (isUndefined(step)) {
    step = end < start ? -1 : 1;
  }
  return times(Math.abs((end - start) / (step || 1)), i => start + step * i);
}

/** @hidden */
let nextId = 1;
/**
 * Generates a unique ID. If `prefix` is given, the ID is appended to it.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,062 bytes
 * - Micro-dash: 38 bytes
 */
function uniqueId(prefix = '') {
  return prefix + nextId++;
}

/**
 * Creates an array with all falsey values removed. The values `false`, `null`, `0`, `""`, `undefined`, and `NaN` are falsey.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 96 bytes
 * - Micro-dash: 34 bytes
 */
function compact(array) {
  return array.filter(identity);
}

/**
 * Creates a new array concatenating `array` with any additional arrays and/or values.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,410 bytes
 * - Micro-dash: 7 bytes
 */
function concat(array, ...values) {
  return array.concat(...values);
}
function remove(array, predicate) {
  const removed = [];
  for (let i = array.length; --i >= 0;) {
    if (predicate(array[i], i)) {
      removed.unshift(array[i]);
      array.splice(i, 1);
    }
  }
  return removed;
}

/**
 * This method is like `pull` except that it accepts an array of values to remove.
 *
 * **Note:** Unlike `difference`, this method mutates array.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 815 bytes
 * - Micro-dash: 174 bytes
 */
function pullAll(array, values) {
  for (const value of values.slice()) {
    remove(array, item => Object.is(item, value));
  }
  return array;
}
// TODO: return type could be narrower?

/**
 * Flattens `array` a single level deep.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,183 bytes
 * - Micro-dash: 26 bytes
 */
function flatten(array) {
  return [].concat(...array);
}

/**
 * Creates an array of array values not included in the other given arrays. The order and references of result values are determined by the first array.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 6,258 bytes
 * - Micro-dash: 249 bytes
 */
function difference(array, ...values) {
  return pullAll(array.slice(), flatten(values));
}

/**
 * Gets all but the last element of `array`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 207 bytes
 * - Micro-dash: 15 bytes
 */
function initial(array) {
  return array.slice(0, -1);
}

/**
 * Gets the last element of array.
 *
 * Differences from lodash:
 * - no special consideration is given to string-keyed values set on the array
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 68 bytes
 * - Micro-dash: 23 bytes
 */
function last(array) {
  return array[array.length - 1];
}

/**
 * Removes all given values from array using `SameValueZero` for equality comparisons.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,000 bytes
 * - Micro-dash: 166 bytes
 */
function pull(array, ...values) {
  return pullAll(array, values);
}

/**
 * Removes elements from array corresponding to indexes and returns an array of removed elements.
 *
 * *Note:* This method mutates `array`.
 *
 * Differences from lodash:
 * - behavior is undefined when attempting to pull attributes keyed with anything other than positive integers
 * - does not support deep paths
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 7,672 bytes
 * - Micro-dash: 156 bytes
 */
function pullAt(array, ...indexes) {
  const flattenedIndexes = flatten(indexes);
  const result = flattenedIndexes.map(i => array[i]);
  let lastI;
  for (const i of flattenedIndexes.sort((a, b) => b - a)) {
    if (i !== lastI) {
      array.splice(i, 1);
    }
    lastI = i;
  }
  return result;
}

/**
 * Uses a binary search to determine the lowest index at which `value` should be inserted into `array` in order to maintain its sort order.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,266 bytes
 * - Micro-dash: 239 bytes
 */
function sortedIndex(array, value) {
  let min = 0;
  let max = array ? array.length - 1 : 0;
  while (max > min) {
    const mid = Math.floor((max + min) / 2);
    if (compareValues(array[mid], value) < 0) {
      min = mid + 1;
    } else {
      max = mid;
    }
  }
  return min;
}

/**
 * Creates an array of unique values, in order, from all given `arrays`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 6,197 bytes
 * - Micro-dash: 50 bytes
 */
function union(...arrays) {
  return Array.from(new Set(flatten(arrays)));
}

/**
 * Creates a duplicate-free version of an array, using `SameValueZero` for equality comparisons, in which only the first occurrence of each element is kept. The order of result values is determined by the order they occur in the array.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 4,580 bytes
 * - Micro-dash: 19 bytes
 */
function uniq(array) {
  return Array.from(new Set(array));
}

/**
 * This method is like `_.uniq` except that it accepts `iteratee` which is invoked for each element in `array` to generate the criterion by which uniqueness is computed. The order of result values is determined by the order they occur in the array.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 9,004 bytes
 * - Micro-dash: 89 bytes
 */
function uniqBy(array, iteratee) {
  const seen = new Set();
  return array.filter(element => {
    const key = iteratee(element);
    const isNew = !seen.has(key);
    seen.add(key);
    return isNew;
  });
}

/**
 * Creates an array excluding all given values.
 *
 * Differences from lodash:
 * - Uses triple equals rather than `SameValueZero`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 5,643 bytes
 * - Micro-dash: 46 bytes
 */
function without(array, ...values) {
  return array.filter(item => values.indexOf(item) === -1);
}
function zip(...arrays) {
  const length = Math.max(0, ...arrays.map(a => a ? a.length : 0));
  return times(length, i => arrays.map(a => a ? a[i] : undefined));
}
function zipObject(props, values) {
  return transform(props, (accumulator, prop, index) => {
    accumulator[prop] = values[index];
  });
}
function bindKey(object, key, ...partials) {
  return (...args) => object[key](...partials, ...args);
}
function partial(func, ...partials) {
  return func.bind(undefined, ...partials);
}
function curry(func, arity = func.length) {
  return function (...args) {
    if (args.length < arity) {
      return curry(partial(func, ...args), arity - args.length);
    } else {
      return func.apply(this, args);
    }
  };
}

/**
 * Creates a debounced function that delays invoking `func` until after `wait` milliseconds have elapsed since the last time the debounced function was invoked. The debounced function comes with a `cancel` method to cancel delayed `func` invocations. The `func` is invoked with the last arguments provided to the debounced function.
 *
 * If `wait` is 0, `func` invocation is deferred until to the next tick, similar to `setTimeout` with a timeout of `0`.
 *
 * Differences from lodash:
 * - the debounced function does not come with a `flush` method
 * - does not accept options
 * - does not return the results of the last invocation
 * - does not make any guarantees about the value of `this` in `func`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 2,064 bytes
 * - Micro-dash: 124 bytes
 */
function debounce(func, wait = 0) {
  let timeoutId;
  const cancel = () => {
    clearTimeout(timeoutId);
  };
  const debounced = (...args) => {
    cancel();
    timeoutId = setTimeout(func, wait, ...args);
  };
  return Object.assign(debounced, {
    cancel
  });
}

/**
 * Creates a function that memoizes the result of `func`. If `resolver` is provided, it determines the cache key for storing the result based on the arguments provided to the memoized function. By default, the first argument provided to the memoized function is used as the map cache key. The `func` is invoked with the `this` binding of the memoized function.
 *
 * **Note:** The cache is exposed as the `cache` property on the memoized function.
 *
 * Differences from lodash:
 * - does not coerce keys to a string; any object can be used as in an ES6 `Map`
 * - does not let you customize cache creation
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,852 bytes
 * - Micro-dash: 210 bytes
 *
 * @param resolver The function to resolve the cache key.
 */
function memoize(func, resolver = identity) {
  const memoized = function () {
    const cache = memoized.cache;
    const key = resolver.apply(this, arguments);
    if (cache.has(key)) {
      return cache.get(key);
    } else {
      const value = func.apply(this, arguments);
      cache.set(key, value);
      return value;
    }
  };
  memoized.cache = new Map();
  return memoized;
}

/**
 * Creates a function that is restricted to invoking `func` once. Repeat calls to the function return the value of the first invocation. The `func` is invoked with the `this` binding and arguments of the created function.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,423 bytes
 * - Micro-dash: 87 bytes
 */
function once(func) {
  let result;
  let needsCall = true;
  return function (...args) {
    if (needsCall) {
      needsCall = false;
      result = func.apply(this, args);
      // allow func and any of its variables to be garbage collected
      func = 0;
    }
    return result;
  };
}

/**
 * Creates a throttled function that only invokes `func` at most once per every `wait` milliseconds. The throttled function comes with a `cancel` method to cancel delayed `func` invocations and a `flush` method to immediately invoke them. Provide `options` to indicate whether `func` should be invoked on the leading and/or trailing edge of the `wait` timeout. The `func` is invoked with the last arguments provided to the throttled function.
 *
 *  **Note**: If `leading` and `trailing` options are `true`, `func` is invoked on the trailing edge of the timeout only if the throttled function is invoked more than once during the `wait` timeout.
 *
 * If `wait` is 0 and `leading` is `false`, `func` invocation is deferred until to the next tick, similar to `setTimeout` with a timeout of 0.
 *
 * Differences from lodash:
 * - does not return the results of the last invocation
 * - does not make any guarantees about the value of `this` in `func`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 2,290 bytes
 * - Micro-dash: 339 bytes
 */
function throttle(func, wait = 0, {
  leading = true,
  trailing = true
} = {}) {
  let tail = 0;
  let nextArgs;
  let timeoutId;
  // helpers to save some bytes
  const now = () => performance.now();
  const setNewTail = () => {
    tail = now() + wait;
  };
  const cancel = () => {
    clearTimeout(timeoutId);
    timeoutId = undefined;
    nextArgs = undefined;
    tail = 0;
  };
  const flush = () => {
    if (nextArgs) {
      const args = nextArgs;
      cancel();
      setNewTail();
      func(...args);
    }
  };
  const throttled = (...args) => {
    nextArgs = args;
    const delay = Math.max(0, tail - now());
    if (!delay && (leading || timeoutId)) {
      flush();
    } else if (trailing) {
      if (!delay) {
        setNewTail();
      }
      clearTimeout(timeoutId);
      timeoutId = setTimeout(flush, delay || wait);
    }
  };
  return Object.assign(throttled, {
    cancel,
    flush
  });
}

/**
 * Clamps `number` within the inclusive lower and upper bounds.
 *
 * Differences from lodash:
 * - `lower` is required
 * - does not coerce bounds that are `NaN` to be `0`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,367 bytes
 * - Micro-dash: 51 bytes
 */
// tslint:disable-next-line:variable-name
function clamp(number, lower, upper) {
  return Math.min(upper, Math.max(lower, number));
}

/**
 * Checks if `n` is between `start` and up to, but not including, `end`. If start is greater than end the params are swapped to support negative ranges.
 *
 * Differences from lodash:
 * - `start` and `end` are both required
 * - does not coerce falsey bounds to `0`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,396 bytes
 * - Micro-dash: 59 bytes
 */
// tslint:disable-next-line:variable-name
function inRange(number, start, end) {
  return number >= Math.min(start, end) && number < Math.max(start, end);
}

/**
 * Converts the first character of `string` to upper case.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,951 bytes
 * - Micro-dash: 63 bytes
 */
// tslint:disable-next-line:variable-name
function upperFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

/**
 * Converts the first character of `string` to upper case and the remaining to lower case.
 *
 * Differences from lodash:
 * - requires `string` to be a string
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,948 bytes
 * - Micro-dash: 72 bytes
 */
// tslint:disable-next-line:variable-name
function capitalize(string) {
  return upperFirst(string.toLowerCase());
}

/**
 * Splits `string` into an array of its words.
 *
 * Differences from lodash:
 * - does not accept a `pattern` argument
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 2,361 bytes
 * - Micro-dash: 176 bytes
 */
// tslint:disable-next-line:variable-name
function words(string) {
  return string
  // between lower & upper
  .replace(/([a-z])([A-Z])/g, '$1 $2')
  // between number & letter
  .replace(/(\d)([A-Za-z])/g, '$1 $2').replace(/([A-Za-z])(\d)/g, '$1 $2')
  // before last upper in a sequence followed by lower
  .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3').match(/[A-Za-z0-9]+/g) || [];
}

/**
 * Converts `string` to camel case.
 *
 * Differences from lodash:
 * - requires `string` to be a string
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 6,180 bytes
 * - Micro-dash: 322 bytes
 */
// tslint:disable-next-line:variable-name
function camelCase(string) {
  return words(string).map((w, i) => i ? capitalize(w) : w.toLowerCase()).join('');
}

/**
 * Converts `string`, as a whole, to lower case just like `String#toLowerCase`.
 *
 * Differences from lodash:
 * - requires `string` to be a string
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,001 bytes
 * - Micro-dash: 16 bytes
 */
// tslint:disable-next-line:variable-name
function toLower(string) {
  return string.toLowerCase();
}

/**
 * Converts `string` to [kebab case](https://en.wikipedia.org/wiki/Letter_case#Special_case_styles).
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 5,232 bytes
 * - Micro-dash: 234 bytes
 */
// tslint:disable-next-line:variable-name
function kebabCase(string) {
  return words(string).map(toLower).join('-');
}

/**
 * Converts the first character of `string` to lower case.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 1,951 bytes
 * - Micro-dash: 63 bytes
 */
// tslint:disable-next-line:variable-name
function lowerFirst(string) {
  return string ? string.charAt(0).toLowerCase() + string.slice(1) : '';
}

/**
 * Pads `string` on the left and right sides if it's shorter than `length`. Padding characters are truncated if they can't be evenly divided by `length`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,359 bytes
 * - Micro-dash: 177 bytes
 */
function pad(s, length, chars = ' ') {
  const needed = (length - s.length) / 2;
  return _makePadding(Math.floor(needed), chars) + s + _makePadding(Math.ceil(needed), chars);
}
/** @hidden */
function makePadding(s, length, chars) {
  return _makePadding(length - s.length, chars);
}
/** @hidden */
function _makePadding(needed, chars) {
  return needed > 0 && chars.length > 0 ? chars.repeat(Math.ceil(needed / chars.length)).substr(0, needed) : '';
}

/**
 * Pads `string` on the left side if it's shorter than `length`. Padding characters are truncated if they exceed `length`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,308 bytes
 * - Micro-dash: 161 bytes
 */
function padStart(s, length, chars = ' ') {
  return makePadding(s, length, chars) + s;
}

/**
 * Pads `string` on the right side if it's shorter than `length`. Padding characters are truncated if they exceed `length`.
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 3,308 bytes
 * - Micro-dash: 161 bytes
 */
function padEnd(s, length, chars = ' ') {
  return s + makePadding(s, length, chars);
}

/**
 * Repeats the given string `n` times.
 *
 * Differences from lodash:
 * - does not work as an iteratee for methods like `map`
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 2,357 bytes
 * - Micro-dash: 62 bytes
 */
// tslint:disable-next-line:variable-name
function repeat(string, n) {
  // tslint:disable-next-line:no-bitwise
  return n < 0 ? '' : new Array(n | 0).fill(string).join('');
}

/**
 * Converts `string` to [snake case](https://en.wikipedia.org/wiki/Snake_case)
 *
 * Contribution to minified bundle size, when it is the only function imported:
 * - Lodash: 5,249 bytes
 * - Micro-dash: 235 bytes
 */
// tslint:disable-next-line:variable-name
function snakeCase(string) {
  return words(string).map(toLower).join('_');
}

/*
 * Public API Surface of micro-dash
 */

/**
 * Generated bundle index. Do not edit.
 */

export { bindKey, camelCase, capitalize, castArray, chunk, clamp, clone, cloneDeep, compact, concat, constant, curry, debounce, difference, every, filter, find, findKey, flatMap, flatten, flow, flowRight, forEach, forEachRight, forOwn, forOwnRight, functions, get, groupBy, identity, inRange, includes, initial, invoke, isBoolean, isEmpty, isEqual, isFunction, isMatch, isNil, isNumber, isRegExp, isString, isUndefined, kebabCase, keyBy, keys, last, lowerFirst, map, mapValues, matches, maxBy, memoize, merge, minBy, noop, omit, omitBy, once, pad, padEnd, padStart, partial, pick, pickBy, pull, pullAll, pullAt, random, range, reduce, reduceRight, remove, repeat, round, sample, set, size, snakeCase, some, sortBy, sortedIndex, sumBy, throttle, times, toArray, toLower, toPairs, toString, transform, union, uniq, uniqBy, uniqueId, update, upperFirst, values, without, words, zip, zipObject };
