"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = Object.setPrototypeOf || {
    __proto__: []
  } instanceof Array && function (d, b) {
    d.__proto__ = b;
  } || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Dictionary_1 = require("./Dictionary");
var util = require("./util");
var FactoryDictionary = /** @class */function (_super) {
  __extends(FactoryDictionary, _super);
  /**
   * Creates an empty dictionary.
   * @class <p>Dictionaries map keys to values; each key can map to at most one value.
   * This implementation accepts any kind of objects as keys.</p>
   *
   * <p>The default factory function should return a new object of the provided
   * type. Example:</p>
   * <pre>
   * function petFactory() {
   *  return new Pet();
   * }
   * </pre>
   *
   * <p>If the keys are custom objects a function which converts keys to unique
   * strings must be provided. Example:</p>
   * <pre>
   * function petToString(pet) {
   *  return pet.name;
   * }
   * </pre>
   * @constructor
   * @param {function():V=} defaultFactoryFunction function used to create a
   * default object.
   * @param {function(Object):string=} toStrFunction optional function used
   * to convert keys to strings. If the keys aren't strings or if toString()
   * is not appropriate, a custom function which receives a key and returns a
   * unique string must be provided.
   */
  function FactoryDictionary(defaultFactoryFunction, toStrFunction) {
    var _this = _super.call(this, toStrFunction) || this;
    _this.defaultFactoryFunction = defaultFactoryFunction;
    return _this;
  }
  /**
   * Associates the specified default value with the specified key in this dictionary,
   * if it didn't contain the key yet. If the key existed, the existing value will be used.
   * @param {Object} key key with which the specified value is to be
   * associated.
   * @param {Object} defaultValue default value to be associated with the specified key.
   * @return {*} previous value associated with the specified key, or the default value,
   * if the key didn't exist yet.
   */
  FactoryDictionary.prototype.setDefault = function (key, defaultValue) {
    var currentValue = _super.prototype.getValue.call(this, key);
    if (util.isUndefined(currentValue)) {
      this.setValue(key, defaultValue);
      return defaultValue;
    }
    return currentValue;
  };
  /**
   * Returns the value to which this dictionary maps the specified key.
   * Returns a default value created by the factory passed in the constructor,
   * if this dictionary contains no mapping for this key. The missing key will
   * automatically be added to the dictionary.
   * @param {Object} key key whose associated value is to be returned.
   * @return {*} the value to which this dictionary maps the specified key or
   * a default value if the map contains no mapping for this key.
   */
  FactoryDictionary.prototype.getValue = function (key) {
    return this.setDefault(key, this.defaultFactoryFunction());
  };
  return FactoryDictionary;
}(Dictionary_1.default);
exports.default = FactoryDictionary;
