"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var util = require("./util");
var Dictionary_1 = require("./Dictionary");
var arrays = require("./arrays");
var MultiDictionary = /** @class */function () {
  /**
   * Creates an empty multi dictionary.
   * @class <p>A multi dictionary is a special kind of dictionary that holds
   * multiple values against each key. Setting a value into the dictionary will
   * add the value to an array at that key. Getting a key will return an array,
   * holding all the values set to that key.
   * You can configure to allow duplicates in the values.
   * This implementation accepts any kind of objects as keys.</p>
   *
   * <p>If the keys are custom objects a function which converts keys to strings must be
   * provided. Example:</p>
   *
   * <pre>
   * function petToString(pet) {
   *     return pet.name;
   * }
   * </pre>
   * <p>If the values are custom objects a function to check equality between values
   * must be provided. Example:</p>
   *
   * <pre>
   * function petsAreEqualByAge(pet1,pet2) {
   *     return pet1.age === pet2.age;
   * }
   * </pre>
   * @constructor
   * @param {function(Object):string=} toStrFunction optional function
   * to convert keys to strings. If the keys aren't strings or if toString()
   * is not appropriate, a custom function which receives a key and returns a
   * unique string must be provided.
   * @param {function(Object,Object):boolean=} valuesEqualsFunction optional
   * function to check if two values are equal.
   *
   * @param allowDuplicateValues
   */
  function MultiDictionary(toStrFunction, valuesEqualsFunction, allowDuplicateValues) {
    if (allowDuplicateValues === void 0) {
      allowDuplicateValues = false;
    }
    this.dict = new Dictionary_1.default(toStrFunction);
    this.equalsF = valuesEqualsFunction || util.defaultEquals;
    this.allowDuplicate = allowDuplicateValues;
  }
  /**
   * Returns an array holding the values to which this dictionary maps
   * the specified key.
   * Returns an empty array if this dictionary contains no mappings for this key.
   * @param {Object} key key whose associated values are to be returned.
   * @return {Array} an array holding the values to which this dictionary maps
   * the specified key.
   */
  MultiDictionary.prototype.getValue = function (key) {
    var values = this.dict.getValue(key);
    if (util.isUndefined(values)) {
      return [];
    }
    return arrays.copy(values);
  };
  /**
   * Adds the value to the array associated with the specified key, if
   * it is not already present.
   * @param {Object} key key with which the specified value is to be
   * associated.
   * @param {Object} value the value to add to the array at the key
   * @return {boolean} true if the value was not already associated with that key.
   */
  MultiDictionary.prototype.setValue = function (key, value) {
    if (util.isUndefined(key) || util.isUndefined(value)) {
      return false;
    }
    var array = this.dict.getValue(key);
    if (util.isUndefined(array)) {
      this.dict.setValue(key, [value]);
      return true;
    }
    if (!this.allowDuplicate) {
      if (arrays.contains(array, value, this.equalsF)) {
        return false;
      }
    }
    array.push(value);
    return true;
  };
  /**
   * Removes the specified values from the array of values associated with the
   * specified key. If a value isn't given, all values associated with the specified
   * key are removed.
   * @param {Object} key key whose mapping is to be removed from the
   * dictionary.
   * @param {Object=} value optional argument to specify the value to remove
   * from the array associated with the specified key.
   * @return {*} true if the dictionary changed, false if the key doesn't exist or
   * if the specified value isn't associated with the specified key.
   */
  MultiDictionary.prototype.remove = function (key, value) {
    if (util.isUndefined(value)) {
      var v = this.dict.remove(key);
      return !util.isUndefined(v);
    }
    var array = this.dict.getValue(key);
    if (!util.isUndefined(array) && arrays.remove(array, value, this.equalsF)) {
      if (array.length === 0) {
        this.dict.remove(key);
      }
      return true;
    }
    return false;
  };
  /**
   * Returns an array containing all of the keys in this dictionary.
   * @return {Array} an array containing all of the keys in this dictionary.
   */
  MultiDictionary.prototype.keys = function () {
    return this.dict.keys();
  };
  /**
   * Returns an array containing all of the values in this dictionary.
   * @return {Array} an array containing all of the values in this dictionary.
   */
  MultiDictionary.prototype.values = function () {
    var values = this.dict.values();
    var array = [];
    for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
      var v = values_1[_i];
      for (var _a = 0, v_1 = v; _a < v_1.length; _a++) {
        var w = v_1[_a];
        array.push(w);
      }
    }
    return array;
  };
  /**
   * Returns true if this dictionary at least one value associatted the specified key.
   * @param {Object} key key whose presence in this dictionary is to be
   * tested.
   * @return {boolean} true if this dictionary at least one value associatted
   * the specified key.
   */
  MultiDictionary.prototype.containsKey = function (key) {
    return this.dict.containsKey(key);
  };
  /**
   * Removes all mappings from this dictionary.
   */
  MultiDictionary.prototype.clear = function () {
    this.dict.clear();
  };
  /**
   * Returns the number of keys in this dictionary.
   * @return {number} the number of key-value mappings in this dictionary.
   */
  MultiDictionary.prototype.size = function () {
    return this.dict.size();
  };
  /**
   * Returns true if this dictionary contains no mappings.
   * @return {boolean} true if this dictionary contains no mappings.
   */
  MultiDictionary.prototype.isEmpty = function () {
    return this.dict.isEmpty();
  };
  return MultiDictionary;
}(); // end of multi dictionary
exports.default = MultiDictionary;
